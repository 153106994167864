import React, { JSXElementConstructor, ReactElement, useState } from 'react'
import styled from 'styled-components'

import { ContainerError } from '@/components/_layout/ContainerMessage'
import { FiltersMobileButton } from '@/components/_shared/filters/FiltersMobileButton'
import { FiltersSelectors } from '@/components/_shared/filters/FiltersSelectors'
import { FiltersTags } from '@/components/_shared/filters/FiltersTags'
import { MoreFilters } from '@/components/_shared/filters/MoreFilters'
import { useLessonFilters } from '@/contexts/lessonFilters'
import {
  IFilterRenderersLessonOpts,
  useFilterRenderersLesson,
} from '@/hooks/use-filterRenderersLesson'
import { DEVICE_WIDTH } from '@/utils/constants'

import { CalendarSelector } from './filters/CalendarSelector'
import { LessonListFiltersLoading } from './loaders/LessonListFiltersLoading'

interface IProps {
  filterRenderersLessonOpts: IFilterRenderersLessonOpts
  hiddenCalendar?: boolean
  renderSwitcherComponent?: ReactElement<any, string | JSXElementConstructor<any>>
}

export const LessonListFilters: React.FC<IProps> = ({
  filterRenderersLessonOpts,
  hiddenCalendar = false,
  renderSwitcherComponent,
}) => {
  const [{ lessonFiltersState }, lessonFiltersDispatch] = useLessonFilters()
  const {
    filterRenderersLessonLoading,
    filterRenderersLessonError,
    mainLessonFilterCmpList,
    secondaryLessonFilterCmpList,
    removableLessonFilterCmpList,
  } = useFilterRenderersLesson(filterRenderersLessonOpts)
  const [showFiltersSelector, setShowFiltersSelector] = useState(false)

  if (filterRenderersLessonLoading) {
    return <LessonListFiltersLoading />
  }
  if (filterRenderersLessonError) {
    return <ContainerError />
  }

  const toggleFiltersSelector = () => {
    setShowFiltersSelector((prevState) => !prevState)
  }

  return (
    <Wrapper>
      <StyledFiltersMobileButton
        toggleFiltersSelector={toggleFiltersSelector}
        removableFilterList={removableLessonFilterCmpList}
      />

      <FlexWrapper>
        <FiltersSelectors
          showFiltersSelector={showFiltersSelector}
          toggleFiltersSelector={toggleFiltersSelector}
        >
          {!hiddenCalendar && <StyledCalendarSelector />}

          <MoreFilters
            nbActiveFilters={
              lessonFiltersState.disciplineIds.length +
              lessonFiltersState.postalCodes.length +
              lessonFiltersState.teacherIds.length +
              lessonFiltersState.timeslotIds.length +
              lessonFiltersState.durationIds.length +
              lessonFiltersState.levelIds.length +
              lessonFiltersState.placeIds.length
            }
            mainLessonFilterCmpList={mainLessonFilterCmpList}
            secondaryLessonFilterCmpList={secondaryLessonFilterCmpList}
          />
        </FiltersSelectors>

        {renderSwitcherComponent}
      </FlexWrapper>

      <FiltersTags dispatch={lessonFiltersDispatch}>
        {removableLessonFilterCmpList}
      </FiltersTags>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }
`
const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const StyledFiltersMobileButton = styled(FiltersMobileButton)`
  margin-bottom: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    width: fit-content;
  }
`
const StyledCalendarSelector = styled(CalendarSelector)`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: flex;
  }
`
