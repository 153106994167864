import 'react-calendar/dist/Calendar.css'

import { format, isToday } from 'date-fns'
import React, { useRef, useState } from 'react'
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { CustomCalendar } from '@/components/_custom/CustomCalendar'
import { CalendarIcon } from '@/components/_svg/icons/CalendarIcon'
import { useLessonFilters } from '@/contexts/lessonFilters'
import { useOnClickOutside } from '@/hooks/use-onClickOutside'
import { DEVICE_WIDTH } from '@/utils/constants'
import { formatDate, formatDateAddDays } from '@/utils/date'

export type SelectedCalendarElement = 'today' | 'days' | 'calendar'

interface IProps {
  className?: string
}

export const CalendarSelector: React.FC<IProps> = ({ className }) => {
  const [
    {
      lessonFiltersState: { startAt, endAt },
    },
    lessonFiltersDispatch,
  ] = useLessonFilters()
  const calendarRef = useRef()
  const [showCalendar, setShowCalendar] = useState(false)
  useOnClickOutside(calendarRef, () => setShowCalendar(false))

  const onChange = (value) => {
    lessonFiltersDispatch({
      type: 'setDatesRange',
      datesRange: {
        startAt:
          isToday(value[0]) && isToday(value[1]) ? null : format(value[0], 'yyyy-MM-dd'),
        endAt: format(value[1], 'yyyy-MM-dd'),
      },
    })
    setShowCalendar(false)
  }

  const toggleShowCalendar = () => {
    setShowCalendar((prevValue) => !prevValue)
  }

  return (
    <Wrapper className={className}>
      <div ref={calendarRef}>
        <StyledButton onClick={toggleShowCalendar}>
          {startAt === null && endAt === null ? (
            <FormattedMessage
              defaultMessage="Calendrier"
              description="CalendarSelector: calendar button"
            />
          ) : (
            <FormattedDateTimeRange
              from={startAt ? new Date(startAt) : new Date(endAt)}
              to={endAt ? new Date(endAt) : new Date(startAt)}
              month="short"
              day="2-digit"
              year="numeric"
            />
          )}
          <StyledCalendarIcon />
        </StyledButton>

        <CustomCalendar
          showCalendar={showCalendar}
          startAt={startAt}
          endAt={endAt}
          onClickToday={() => {
            lessonFiltersDispatch({
              type: 'setDatesRange',
              datesRange: { startAt: null, endAt: formatDate('yyyy-MM-dd') },
            })
          }}
          onClickNext7Days={() => {
            lessonFiltersDispatch({
              type: 'setDatesRange',
              datesRange: {
                startAt: formatDate('yyyy-MM-dd'),
                endAt: formatDateAddDays('yyyy-MM-dd', 6),
              },
            })
          }}
          onChange={onChange}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.ms};
  position: relative;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin-bottom: 0;
    width: fit-content;
  }
`

const StyledButton = styled(CustomButton)`
  ${({ theme }) => theme.typo.p};
  ${({ theme }) => theme.typo.bold};

  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    width: inherit;
  }
`
const StyledCalendarIcon = styled(CalendarIcon)`
  fill: ${({ theme }) => theme.color.white};
  height: 20px;
  width: 20px;
`
