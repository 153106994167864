import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { PropertySelector } from '@/components/_shared/filters/PropertySelector'
import { Selector } from '@/components/_shared/filters/Selector'
import { GenericRemovableSelectionList } from '@/components/lesson/lesson/filters/GenericRemovableSelectionList'
import { PropertyDisciplineSelector } from '@/components/lesson/lesson/filters/PropertyDisciplineSelector'
import { useLessonFilters } from '@/contexts/lessonFilters'
import { ILessonFilterKeys } from '@/reducers/lessonFilters'
import { ITeacherGQLQuery } from '@/services/api-graphql-teacher'
import { IPropertyValueArea } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'

import { useSWRPlaceResults } from './swr/useSwr-placeResults'
import { PROPERTY_KEY_SLUGS_1, useSWRPropertyResults } from './swr/useSwr-propertyResults'
import { useSWRTeacherResults } from './swr/useSwr-teacherResults'

export interface IFilterRenderersLessonOpts {
  mainFilterKeys: ILessonFilterKeys[]
  hiddenFilterKeys?: ILessonFilterKeys[]
  teacherGqlQuery?: ITeacherGQLQuery
}
interface IReturnType {
  filterRenderersLessonLoading: boolean
  filterRenderersLessonError: HTTPError
  mainLessonFilterCmpList: JSX.Element[]
  secondaryLessonFilterCmpList: JSX.Element[]
  removableLessonFilterCmpList: JSX.Element[]
}

export const useFilterRenderersLesson = ({
  mainFilterKeys,
  hiddenFilterKeys = [],
  teacherGqlQuery = 'LessonOnsite',
}: IFilterRenderersLessonOpts): IReturnType => {
  const intl = useIntl()
  const router = useRouter()
  const [{ lessonFiltersState }, lessonFiltersDispatch] = useLessonFilters()
  const areaKey = lessonFiltersState.areaKey
  const { teacherListLoading, teacherList, teacherListError } = useSWRTeacherResults({
    gqlQuery: teacherGqlQuery,
    itemsPerPage: 200,
    areaKey,
  })
  const { propertyListLoading, propertyList, propertyListError } =
    useSWRPropertyResults(PROPERTY_KEY_SLUGS_1)
  const { placeListLoading, placeList, placeListError } = useSWRPlaceResults({
    areaKey,
  })

  const filterRenderersLessonLoading =
    (teacherListLoading && !teacherList) ||
    (propertyListLoading && !propertyList) ||
    (!!areaKey && placeListLoading && !placeList)
  const filterRenderersLessonError =
    teacherListError || propertyListError || (!!areaKey && placeListError)

  const {
    mainLessonFilterCmpList,
    secondaryLessonFilterCmpList,
    removableLessonFilterCmpList,
  } = useMemo(() => {
    if (filterRenderersLessonLoading || filterRenderersLessonError) {
      return {
        mainLessonFilterCmpList: [],
        secondaryLessonFilterCmpList: [],
        removableLessonFilterCmpList: [],
      }
    }

    const area =
      areaKey &&
      propertyList
        .find((propKey) => propKey.slug === 'area')
        .propValues.find((propValue) => propValue.slug === areaKey)

    const lessonFilterObjList = buildFilterObjList({
      hiddenFilterKeys,
      lessonFiltersState,
      lessonFiltersDispatch,
      teacherList,
      propertyList,
      placeList,
      area,
      intl,
    })
    const { mainLessonFilterCmpList, secondaryLessonFilterCmpList } = buildSplitCmpList(
      lessonFilterObjList,
      mainFilterKeys
    )
    const removableLessonFilterCmpList = buildRemovableFilterCmpList({
      hiddenFilterKeys,
      lessonFiltersState,
      lessonFiltersDispatch,
      teacherList,
      propertyList,
      placeList,
      area,
    })

    return {
      mainLessonFilterCmpList,
      secondaryLessonFilterCmpList,
      removableLessonFilterCmpList,
    }
  }, [
    filterRenderersLessonLoading,
    filterRenderersLessonError,
    lessonFiltersState,
    router.query.map,
  ])

  return {
    filterRenderersLessonLoading,
    filterRenderersLessonError,
    mainLessonFilterCmpList,
    secondaryLessonFilterCmpList,
    removableLessonFilterCmpList,
  }
}

const buildFilterObjList = ({
  hiddenFilterKeys,
  lessonFiltersState,
  lessonFiltersDispatch,
  teacherList,
  propertyList,
  placeList,
  area,
  intl,
}): { filterKey: ILessonFilterKeys; filterCmp: JSX.Element }[] => {
  const filterObjList = []

  if (!hiddenFilterKeys.includes('disciplineIds')) {
    filterObjList.push({
      filterKey: 'disciplineIds',
      filterCmp: (
        <PropertyDisciplineSelector
          propKey={propertyList.find((propKey) => propKey.slug === 'discipline')}
          selectedValues={lessonFiltersState.disciplineIds}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setDisciplines',
              setDisciplineIds: values,
            })
          }
        />
      ),
    })
  }

  if (!hiddenFilterKeys.includes('postalCodes') && !!area?.children.length) {
    filterObjList.push({
      filterKey: 'postalCodes',
      filterCmp: (
        <Selector
          selectedValues={lessonFiltersState.postalCodes}
          options={area.children.map((areaChild: IPropertyValueArea) => ({
            label: areaChild.title,
            value: areaChild.misc?.postalCode,
          }))}
          placeholder={intl.formatMessage({
            defaultMessage: 'Codes postaux',
            description: 'LessonListFilters: Selector postalCode placeholder',
          })}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setPostalCodes',
              setPostalCodes: values,
            })
          }
        />
      ),
    })
  }

  if (!hiddenFilterKeys.includes('teacherIds')) {
    filterObjList.push({
      filterKey: 'teacherIds',
      filterCmp: (
        <Selector
          selectedValues={lessonFiltersState.teacherIds}
          options={teacherList.collection.map(({ _id, name }) => ({
            label: name,
            value: _id,
          }))}
          placeholder={intl.formatMessage({
            defaultMessage: 'Professeurs',
            description: 'LessonListFilters: Selector teachers placeholder',
          })}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setTeachers',
              setTeacherIds: values,
            })
          }
        />
      ),
    })
  }

  if (!hiddenFilterKeys.includes('timeslotIds')) {
    filterObjList.push({
      filterKey: 'timeslotIds',
      filterCmp: (
        <PropertySelector
          propKey={propertyList.find((propKey) => propKey.slug === 'timeslot')}
          selectedValues={lessonFiltersState.timeslotIds}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setTimeslots',
              setTimeslotIds: values,
            })
          }
        />
      ),
    })
  }

  if (!hiddenFilterKeys.includes('durationIds')) {
    filterObjList.push({
      filterKey: 'durationIds',
      filterCmp: (
        <PropertySelector
          propKey={propertyList.find((propKey) => propKey.slug === 'duration-lesson')}
          selectedValues={lessonFiltersState.durationIds}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setDurations',
              setDurationIds: values,
            })
          }
        />
      ),
    })
  }

  if (!hiddenFilterKeys.includes('levelIds')) {
    filterObjList.push({
      filterKey: 'levelIds',
      filterCmp: (
        <PropertySelector
          propKey={propertyList.find((propKey) => propKey.slug === 'level')}
          selectedValues={lessonFiltersState.levelIds}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setLevels',
              setLevelIds: values,
            })
          }
        />
      ),
    })
  }

  if (!hiddenFilterKeys.includes('placeIds') && placeList) {
    filterObjList.push({
      filterKey: 'levelIds',
      filterCmp: (
        <Selector
          selectedValues={lessonFiltersState.placeIds}
          options={placeList.collection.map(({ _id, title }) => ({
            label: title,
            value: _id,
          }))}
          placeholder={intl.formatMessage({
            defaultMessage: 'Studio',
            description: 'LessonListFilters: Selector studio placeholder',
          })}
          onSelectFn={(values: number[]) =>
            lessonFiltersDispatch({
              type: 'setPlaces',
              setPlaceIds: values,
            })
          }
        />
      ),
    })
  }

  return filterObjList
}

const buildSplitCmpList = (
  filterObjList: { filterKey: ILessonFilterKeys; filterCmp: JSX.Element }[],
  mainFilterKeys: ILessonFilterKeys[]
) =>
  filterObjList.reduce(
    (acc, filter) => {
      if (mainFilterKeys.includes(filter.filterKey)) {
        return {
          ...acc,
          mainLessonFilterCmpList: [...acc.mainLessonFilterCmpList, filter.filterCmp],
        }
      }

      return {
        ...acc,
        secondaryLessonFilterCmpList: [
          ...acc.secondaryLessonFilterCmpList,
          filter.filterCmp,
        ],
      }
    },
    { mainLessonFilterCmpList: [], secondaryLessonFilterCmpList: [] }
  )

const buildRemovableFilterCmpList = ({
  hiddenFilterKeys,
  lessonFiltersState,
  lessonFiltersDispatch,
  teacherList,
  propertyList,
  placeList,
  area,
}) =>
  Object.entries(lessonFiltersState).flatMap(
    ([filterKey, filterValues]: [ILessonFilterKeys, any]) => {
      if (['pageIndex', 'sortKey', 'areaKey', 'startAt'].includes(filterKey)) {
        return []
      }

      if (hiddenFilterKeys.includes(filterKey)) {
        return []
      }

      if ((Array.isArray(filterValues) && !filterValues.length) || !filterValues) {
        return []
      }

      return [
        <GenericRemovableSelectionList
          filterKey={filterKey}
          filterValues={filterValues}
          lessonFiltersDispatch={lessonFiltersDispatch}
          propertyList={propertyList}
          areaChildrenList={area?.children}
          placeList={placeList}
          teacherList={teacherList}
          key={`rmFilters-${filterKey}`}
        />,
      ]
    }
  )
