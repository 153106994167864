import { useEffect, useState } from 'react'

const getWindowDimensions = () => {
  if (typeof window === 'undefined') {
    return { windowWidth: 0, windowHeight: 0 }
  }

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window

  return {
    windowWidth,
    windowHeight,
  }
}

export const useWindowDimensions = (): { windowWidth: number; windowHeight: number } => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowDimensions(getWindowDimensions())
    })

    return () =>
      window.removeEventListener('resize', () => {
        setWindowDimensions(getWindowDimensions())
      })
  }, [])

  return windowDimensions
}
