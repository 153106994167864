import React, { Children, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'

import { CustomButton } from '@/components/_custom/CustomButton'
import { Modal } from '@/components/_shared/Modal'
import { useWindowDimensions } from '@/hooks/use-windowDimensions'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  nbActiveFilters: number
  mainLessonFilterCmpList: JSX.Element[]
  secondaryLessonFilterCmpList: JSX.Element[]
}

export const MoreFilters: React.FC<IProps> = ({
  nbActiveFilters,
  mainLessonFilterCmpList,
  secondaryLessonFilterCmpList,
}) => {
  const intl = useIntl()
  const [modalOpen, setModalOpen] = useState(false)
  const { windowWidth } = useWindowDimensions()

  return (
    <>
      {mainLessonFilterCmpList}

      <DesktopWrapper>
        <StyledButton onClick={() => setModalOpen(true)} outlined>
          <FormattedMessage
            defaultMessage="+ de filtres"
            description="MoreFilters: more filters button"
          />
          {!!nbActiveFilters && <NbActiveFilters>{nbActiveFilters}</NbActiveFilters>}
        </StyledButton>

        <Modal
          isOpen={modalOpen && windowWidth >= DEVICE_WIDTH.TABLET}
          onRequestClose={() => setModalOpen(false)}
          shouldCloseOnOverlayClick={true}
          modalTitle={intl.formatMessage({
            defaultMessage: '+ de filtres',
            description: 'MoreFilters: modalTitle',
          })}
        >
          <ModalContent>
            {Children.map(mainLessonFilterCmpList, (child, i) => (
              <React.Fragment key={`modalFilters-main${i}`}>{child}</React.Fragment>
            ))}

            {Children.map(secondaryLessonFilterCmpList, (child, i) => (
              <React.Fragment key={`modalFilters-secondary${i}`}>{child}</React.Fragment>
            ))}
          </ModalContent>
        </Modal>
      </DesktopWrapper>

      <MobileWrapper>{secondaryLessonFilterCmpList}</MobileWrapper>
    </>
  )
}

const DesktopWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: block;
  }
`
const MobileWrapper = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
const StyledButton = styled(CustomButton)`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    display: flex;
    gap: 0;
    padding-left: ${({ theme }) => theme.spacing.s};
    padding-right: ${({ theme }) => theme.spacing.s};
  }
`
const NbActiveFilters = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.terracota};
  border-radius: ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.color.white};
  display: inline-flex;
  height: ${({ theme }) => theme.spacing.m};
  justify-content: center;
  margin: -${({ theme }) => theme.spacing.xs} 0 -${({ theme }) => theme.spacing.xs}
    ${({ theme }) => theme.spacing.xs};
  width: ${({ theme }) => theme.spacing.m};
`
const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.s};

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }
`
