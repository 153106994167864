import React, { JSXElementConstructor, ReactElement } from 'react'

import { PaginatorWrapper } from '@/components/_shared/paginator/PaginatorWrapper'
import { useLessonFilters } from '@/contexts/lessonFilters'
import {
  IArgs as IFiltersSyncUrlArgs,
  useSyncUrlFilters,
} from '@/hooks/use-syncUrlFilters'
import { ILessonFiltersState } from '@/reducers/lessonFilters'
import {
  buildGqlVariablesFromLessonFilters,
  IGQLLessonVariables,
} from '@/services/api-graphql-lesson'
import { IGQLLessonReplayVariables } from '@/services/api-graphql-lessonReplay'

interface IProps {
  pageComponent: ReactElement<any, string | JSXElementConstructor<any>>
  syncUrlConfig?: Pick<IFiltersSyncUrlArgs<ILessonFiltersState>, 'hiddenFilterKeys'>
  buildGqlVariableFn?: (
    lessonFiltersState: ILessonFiltersState
  ) => IGQLLessonVariables | IGQLLessonReplayVariables
}

export const LessonListPaginated: React.FC<IProps> = ({
  pageComponent,
  syncUrlConfig,
  buildGqlVariableFn = buildGqlVariablesFromLessonFilters,
}) => {
  const [{ lessonFiltersState, initialLessonFiltersState }, lessonFiltersDispatch] =
    useLessonFilters()
  useSyncUrlFilters<ILessonFiltersState>({
    filtersState: lessonFiltersState,
    initialFiltersState: initialLessonFiltersState,
    filtersDispatch: lessonFiltersDispatch,
    scope: 'lesson',
    hiddenFilterKeys: syncUrlConfig?.hiddenFilterKeys,
  })

  const gqlVariables = buildGqlVariableFn(lessonFiltersState)

  return (
    <>
      {React.cloneElement(pageComponent, { gqlVariables })}

      <PaginatorWrapper currPageIndex={lessonFiltersState.pageIndex} />
    </>
  )
}
