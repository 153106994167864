import { addMonths } from 'date-fns'
import { useRouter } from 'next/router'
import React from 'react'
import Calendar, { CalendarProps } from 'react-calendar'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { LeftChevronIcon } from '@/components/_svg/icons/ChevronIcon'
import { DEVICE_WIDTH } from '@/utils/constants'
import { isDateRangeNext7Days, isDatesRangeToday } from '@/utils/date'

import { CustomButton } from './CustomButton'

type IProps = Pick<CalendarProps, 'onChange'> & {
  showCalendar: boolean
  startAt: string
  endAt: string
  onClickToday: () => void
  onClickNext7Days: () => void
  className?: string
}

export const CustomCalendar: React.FC<IProps> = ({
  showCalendar,
  startAt,
  endAt,
  onClickToday,
  onClickNext7Days,
  onChange,
  className,
}) => {
  const { locale } = useRouter()

  return (
    <CalendarContainer $isVisible={showCalendar} className={className}>
      <Calendar
        locale={locale}
        onChange={onChange}
        nextLabel={<StyledRightChevronIcon />}
        next2Label={null}
        prevLabel={<StyledLeftChevronIcon />}
        prev2Label={null}
        maxDate={addMonths(new Date(), 2)}
        minDate={new Date()}
        selectRange
        value={[
          startAt ? new Date(startAt) : new Date(),
          endAt ? new Date(endAt) : new Date(),
        ]}
      />

      <BottomWrapper>
        <StyledButton
          onClick={onClickToday}
          outlined={!isDatesRangeToday(startAt, endAt)}
        >
          <FormattedMessage
            defaultMessage="Aujourd'hui"
            description="CustomCalendar: today button"
          />
        </StyledButton>

        <StyledButton onClick={onClickNext7Days} outlined={!isDateRangeNext7Days}>
          <FormattedMessage
            defaultMessage="7 prochains jours"
            description="CustomCalendar: next 7 days button"
          />
        </StyledButton>
      </BottomWrapper>
    </CalendarContainer>
  )
}

const CalendarContainer = styled.div<{ $isVisible: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.spacing.ml};
  box-shadow: ${({ theme }) => theme.boxShadow.neutral};
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  margin-top: ${({ theme }) => theme.spacing.s};
  overflow: hidden;

  .react-calendar {
    border: none;
    width: 100%;

    & * {
      outline: none;
    }

    &__navigation {
      align-items: center;
      background-color: ${({ theme }) => theme.color.terracota};
      display: flex;
      height: fit-content;
      justify-content: space-between;
      margin-bottom: ${({ theme }) => theme.spacing.xs};
      padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};

      button {
        &:enabled:hover,
        &:enabled:focus {
          background-color: transparent;
        }

        &[disabled] {
          background-color: transparent;
          visibility: hidden;
        }
      }

      &__arrow {
        align-items: center;
        display: flex;
        justify-content: flex-start;

        & svg {
          stroke: ${({ theme }) => theme.color.white};
        }
      }

      &__next-button {
        justify-content: flex-end;
      }

      &__label__labelText {
        ${({ theme }) => theme.typo.p};
        ${({ theme }) => theme.typo.bold};
        color: ${({ theme }) => theme.color.white};
        text-transform: capitalize;
      }
    }

    &__month-view {
      &__weekdays {
        ${({ theme }) => theme.typo.mention};
        color: ${({ theme }) => theme.color.grey};
        text-transform: lowercase;
        margin-bottom: ${({ theme }) => theme.spacing.xs};

        &__weekday {
          padding: 0;

          & abbr {
            font-weight: normal;
            text-decoration: none;
          }
        }
      }

      &__days {
        margin-bottom: ${({ theme }) => theme.spacing.s};

        &__day {
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 0;

          & abbr {
            ${({ theme }) => theme.typo.mention};
            ${({ theme }) => theme.typo.bold};
            width: ${({ theme }) => theme.spacing.ms};
            height: ${({ theme }) => theme.spacing.ms};

            /* background-color: ${({ theme }) => theme.color.terracota}; */
            border-radius: 50%;
            margin: ${({ theme }) => theme.spacing.xxs} 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &--weekend {
            color: inherit;
          }

          &--neighboringMonth {
            color: ${({ theme }) => theme.color.greyLight};
          }
        }
      }
    }

    &__tile {
      background: transparent;

      /* color on hover */
      &:enabled:hover {
        background: transparent;

        & > * {
          background: ${({ theme }) => theme.color.greyLighter};
        }
      }

      /* color of today */
      &--now {
        background-color: transparent;

        & abbr {
          border: 1px solid ${({ theme }) => theme.color.terracotaDark};
        }
      }

      /* color if selected */
      &--active {
        background-color: transparent;

        &:enabled:focus {
          background-color: transparent;
        }

        & abbr,
        &:enabled:hover abbr {
          background-color: ${({ theme }) => theme.color.black};
          color: ${({ theme }) => theme.color.white};
        }
      }

      /* color of range dates */
      &--rangeStart,
      &--rangeEnd,
      &--hoverEnd,
      &--hasActive,
      &--range,
      &--hover {
        background-color: transparent;

        &:enabled:focus {
          background-color: transparent;
        }

        & abbr,
        &:enabled:hover abbr {
          background-color: ${({ theme }) => theme.color.terracotaLight};
          color: ${({ theme }) => theme.color.white};
        }
      }

      /* color of disabled dates */
      &[disabled] {
        color: ${({ theme }) => theme.color.greyLighter};
        cursor: default;

        & abbr {
          background-color: transparent;
        }
      }
    }

    &__year-view__months__month {
      & abbr {
        border: none;
      }

      &:enabled:hover {
        background: ${({ theme }) => theme.color.greyLighter} !important;

        & abbr {
          background: transparent;
        }
      }
    }
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    position: absolute;
    width: 340px;
  }
`
const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  fill: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
const StyledRightChevronIcon = styled(StyledLeftChevronIcon)`
  transform: rotate(180deg);
`
const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.s};
  padding: 0 ${({ theme }) => theme.spacing.s};

  & > *:first-child {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }
`
const StyledButton = styled(CustomButton)`
  ${({ theme }) => theme.typo.mention}

  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.s}`}
`
